<script lang="ts" setup>
import {ref, watch, computed} from 'vue';
import {useCartStore} from '@/stores/cart';

const props = defineProps<{
  styles: ProductStyle[];
  selectedStyle: ProductStyle | null;
}>();

const emits = defineEmits(['update:selectedStyle']);

const cartStore = useCartStore();

const selectedStyleQuantity = computed(() => {
  if (props.selectedStyle) {
    return cartStore.getQuantity(props.selectedStyle.id);
  }
  return 0;
});

const anyStyleInCart = computed(() => {
  return stylesInCart.value.length > 0;
});

const styles = computed(() => {
  return props.styles
      .map(style => ({...style, quantity: cartStore.getQuantity(style.id)}));
});

const stylesInCart = computed(() => {
  return props.styles
      .map(style => ({...style, quantity: cartStore.getQuantity(style.id)}))
      .filter(style => style.quantity > 0);
});

function addToCart() {
  if (props.selectedStyle) {
    cartStore.addToCart(props.selectedStyle.id);
  }
}

function updateQuantity(newQuantity: number) {
  if (props.selectedStyle) {
    cartStore.updateQuantity(props.selectedStyle.id, newQuantity);
  }
}

function selectStyleFromCart(style: ProductStyle) {
  emits('update:selectedStyle', style);
}
</script>

<template>
  <div class="cart-control is-flex-mobile is-justify-content-center">
    <template v-for="style in styles">
      <template v-if="style.id === selectedStyle.id">
        <button
            class="button add-to-cart"
            :style="{ 'box-shadow': `0 0 25px -7px ${selectedStyle?.colour_hex}` }"
            v-if="!selectedStyleQuantity"
            @click.stop="addToCart"
        >
          Add to Cart
        </button>
        <div class="pill-outside" v-if="selectedStyleQuantity">
          <div class="cart-pill">
            <div class="cart-button-outer">
              <div class="cart-button button minus" :class="{ disabled: selectedStyleQuantity === 0}"
                   @click.stop="updateQuantity(Math.max(0, selectedStyleQuantity - 1))"
              >-
              </div>
            </div>
            <div class="cart-item selected"

                 @mousedown="selectStyleFromCart(style)"
                 @click.prevent.stop=""
            >
              <div class="inner">
                <div class="depress">
                  <span class="name">{{ style.name }}</span>
                  <br>
                  <span class="qty">
                            <span
                                :style="{ color: style.id === selectedStyle.id ? selectedStyle?.colour_hex : ''}">×</span>
                            {{ style.quantity }}
                        </span>
                </div>
              </div>
            </div>
            <div class="cart-button-outer">
              <div class="cart-button button plus"
                   @click.stop="updateQuantity(selectedStyleQuantity + 1)"
              >+
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "scss/variables";
@import "bulma/sass/utilities/mixins";

$darkColour: #d5d3d0;
$hoverColour: rgba(210, 210, 210, 0.75);

.pill-outside {
  display: inline-block;
  user-select: none;
  border-radius: 10px;
  border: 1px solid rgba(38, 38, 38, 0.40);

  .cart-pill {
    display: flex;
    align-items: center;
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid $darkColour;
    border-radius: 10px;
    background: $darkColour;

    @include mobile {
      height: 48px;
    }
  }

  .cart-control,
  .cart-item,
  .cart-button {
    height: 36px;
  }

  .cart-item {
    display: inline-block;
    cursor: pointer;
    border: 2px solid $darkColour;
    text-align: center;
    line-height: 12px;
    font-size: 10px;
    transition: box-shadow 0.1s ease-in-out;
    background: $darkColour;
    color: #989898;
    min-width: 20px;

    .inner {
      border: 1px solid #9d9d9d;
      padding: 3px 10px;
      margin-top: 1px;
      height: 31px;
      line-height: 11px;
      background: #d3d3d3;
      box-shadow: inset 2px 2px 2px rgb(206, 205, 205, 1), inset -2px -2px 2px rgb(206, 205, 205, 1);
      border-radius: 4px;
      transition: background 0.3s ease-in-out;

      &:hover {
        transition: background 0.2s ease-in-out;
      }

    }

    .name {
      font-size: 10px;
    }

    .qty {
      font-size: 14px;
    }

    &.selected,
    &:hover {
      color: #000;
    }

    &.selected {
      .inner {
        background: rgba(255, 255, 255, 0.90);
        box-shadow: inset 2px 2px 2px rgb(206, 205, 205, 1), inset -2px -2px 2px rgb(206, 205, 205, 1);
      }
    }

    &:active {
      .inner {
        box-shadow: inset 3px 3px 4px rgb(148, 142, 142, 0.8), inset -3px -3px 4px rgb(148, 142, 142, 0.8);

        .depress {
          scale: 0.9;
        }
      }
    }
  }

  .cart-button-outer {
    border: 1px;
    border-radius: 5px;

    .cart-button {
      display: inline-block;
      width: 32px;
      font-size: 20px;
      padding-top: 0;
      padding-left: 2px;
      cursor: pointer;
      text-align: center;
      height: 32px;
      border: 1px solid $darkColour;
      border-radius: 5px;
      line-height: 30px;
      margin: 0;
      transition: font-size 0.1s ease-in-out, padding-left 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

      &.minus {
        text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.9);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-right: 0;
      }

      &.plus {
        text-shadow: 1px 1px 22px rgba(0, 0, 0, 0.9);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-left: 1px;
        padding-right: 2px;
      }

      &:hover {
        box-shadow: inset 2px 5px 10px rgba(0, 0, 0, 0.15), inset -2px -5px 10px rgba(255, 255, 255, 0.3);
        border-color: #9d9d9d;
      }

      &:active {
        box-shadow: inset 2px 5px 5px rgba(0, 0, 0, 0.3), inset -2px -5px 5px rgba(255, 255, 255, 0.5);
        font-size: 20px;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

.buy-now {
  transition: scale 0.1s ease-in-out;
  scale: 0.9;
  margin-top: 4px;

  &:hover {
    transition: scale 0.2s ease-in-out;
    scale: 1.1;
  }
}

.add-to-cart {
  transition: box-shadow 0.2s ease-in-out, scale 0.2s ease-in-out, text-shadow 0.2s ease-in-out;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

  &:hover {
    scale: 1.1;
  }

  &:active {
    scale: 1.05;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  }
}

.cart-button,
.add-to-cart {
  font-weight: bold;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out, scale 0.5s ease-in-out;
}

.cart-button {
  background-color: $darkColour;
  border-color: $darkColour;
  color: #fff;

  &:hover {
    transition: scale 0.1s ease-in-out;
    background-color: $hoverColour;
    border-color: $hoverColour;
    color: #8a8a8a;
  }
}

.add-to-cart {
  background-color: $pink;
  border-color: $pink;
  color: #fff;

  @include mobile {
    font-size: 1.25rem;
  }

  &:hover {
    transition: scale 0.1s ease-in-out;
    color: #e3e3e3;
  }
}
</style>
