<script lang="ts" setup>
import {ref, computed, watch, onMounted} from "vue";

const emits = defineEmits(['update:selectedStyle']);

const props = defineProps<{
    styles: ProductStyle[];
    selectedStyle: ProductStyle;
}>();

const localSelectedStyle = ref(props.selectedStyle);
const currentIndex = ref(0);
const images = computed(() => {
    return props.styles.flatMap((style) => style.images);
});
const updateSelectedStyle = (style: ProductStyle) => {
    localSelectedStyle.value = style;
    emits('update:selectedStyle', style);
};

onMounted(() => {
    setCurrentIndexValue(localSelectedStyle.value);
});

watch(
    () => props.selectedStyle,
    (newStyle) => {
        if (newStyle === localSelectedStyle.value) {
            return;
        }
        localSelectedStyle.value = newStyle;
        setCurrentIndexValue(newStyle);
    },
    {immediate: true}
);

const setCurrentIndexValue = (newStyle: ProductStyle) => {
    const styleIndex = props.styles.findIndex((style) => style.id === newStyle.id);
    if (styleIndex >= 0) {
        let index = 0;
        for (let i = 0; i < styleIndex; i++) {
            index += props.styles[i].images.length;
        }

        if (props.styles[styleIndex].images.length) {
          currentIndex.value = index; // Change to the index of the first image of the changed to style.
        }
    }
};

const findStyleByImage = (image) => {
    let foundStyle = null;
    let foundStyleIndex = -1;
    for (let i = 0; i < props.styles.length; i++) {
        const style = props.styles[i];
        const imageIndex = style.images.findIndex(
            (img) => img.original.url === image.original.url
        );
        if (imageIndex !== -1) {
            foundStyle = style;
            foundStyleIndex = i;
            break;
        }
    }
    return {style: foundStyle, index: foundStyleIndex};
};

const prev = () => {
    updateStyleByIndex(currentIndex.value > 0 ? currentIndex.value - 1 : images.value.length - 1);
};

const next = () => {
    updateStyleByIndex(currentIndex.value < images.value.length - 1 ? currentIndex.value + 1 : 0);
};

const setIndex = (index = 0) => {
    updateStyleByIndex(index);
};

const updateStyleByIndex = (index) => {
    currentIndex.value = index;
    const {style, index: styleIndex} = findStyleByImage(images.value[index]);
    if (style) {
        updateSelectedStyle(style);
    }
};
</script>

<template>
    <div class="carousel">
        <div
                class="carousel-content"
        >
            <button v-if="images?.length > 1" @click.stop="prev" class="carousel-arrow prev"><span>&#8249;</span></button>
            <button v-if="images?.length > 1" @click.stop="next" class="carousel-arrow next"><span>&#8250;</span></button>
            <div
                    v-for="(image, index) in images"
                    :key="image.id"
                    :class="{ active: index === currentIndex, 'carousel-item': true }"
                    :style="{ transform: index === currentIndex ? 'translateX(0)' : index < currentIndex ? 'translateX(-100%)' : 'translateX(100%)' }"
            >
                <nuxt-img
                        :src="image.original.url"
                        :alt="findStyleByImage(image).style.name"
                        draggable="false"
                        sizes="xs:720px md:640px"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "scss/variables";

.carousel {
  position: relative;
  border-radius: 15px 15px 0 0;

  .carousel-content {
    overflow: hidden;
    position: relative;
    padding-bottom: 70%;
    border-radius: 15px 15px 0 0;
    height: 0;
    background-color: #fff;

    .carousel-arrow {
      z-index: 10;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      font-size: 4rem;
      align-items: center;
      justify-content: center;
      scale: 1.5;
      cursor: pointer;
      width: 10%;
      min-width: 65px;

      span {
        text-shadow: 0 0 15px #363636;
        -webkit-text-fill-color: #545454;
        -webkit-text-stroke-color: #363636;
        -webkit-text-stroke-width: 2px;
        opacity: 0.4;
        transition: opacity 0.5s, text-shadow 0.2s, transform 0.2s, padding 0.2s;
        width: 50px;
        transform: scaleX(0.6);
      }

      &:active {
        span {
          padding-top: 2px;
        }
      }

      &:hover {
        transition: background-color 0s;

        span {
          text-shadow: 2px 7px 10px rgba(255, 255, 255, 0.5), -10px -7px 10px rgba(255, 255, 255, 0.5), 3px -7px 10px rgba(255, 255, 255, 0.5), -12px 7px 10px rgba(255, 255, 255, 0.5), 12px 0 10px rgba(255, 255, 255, 0.5);
          opacity: 0.8;
          -webkit-text-stroke-width: 1px;
        }
      }
    }

    .prev {
      left: 0;
    }

    .next {
      right: 0;
    }

    .carousel-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 0.4s;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>