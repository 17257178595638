<script setup lang="ts">
import ProductCardImageCarousel from "~/components/product/ProductCardImageCarousel.vue";
import ProductCardStyleChooser from "~/components/product/ProductCardStyleChooser.vue";
import ProductPriceDisplay from "~/components/product/ProductPriceDisplay.vue";
import ProductCardCartManager from "~/components/product/ProductCardCartManager.vue";

const props = defineProps({
  product: Object,
  returnTo: {
    type: String,
    default: null
  },
  showDescription: {
    type: Boolean,
    default: true
  }
});

function handleClick(slug) {
  if (event.button) {
    return window.open(getUrlPath(slug), '_blank');
  }

  const router = useRouter()
  router.push({
    path: getUrlPath(slug),
  })
}

function getUrlPath(slug) {
  return `/product/${slug}` // TODO: include style slug as well!
}

const config = useRuntimeConfig()

const selectedStyle = ref(props.product.styles[0]);
</script>

<template>
  <div
      class="card"
      @click.stop="handleClick(product.slug)"
      @click.middle.stop="handleClick(product.slug)"
  >
    <div class="card-image">
      <ProductCardImageCarousel
          :styles="product.styles"
          v-model:selectedStyle="selectedStyle"
      />
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-5">
            <NuxtLink @click.middle.stop="" class="revert" :href="getUrlPath(product.slug)">{{
                product.title
              }}
            </NuxtLink>
          </p>
        </div>
      </div>
      <div class="content">
        <div class="mt-1">
          <ProductPriceDisplay :regular-price="product.price" :sale-price="product.sale_price"/>
        </div>

        <div v-if="props.showDescription" class="description mt-1">
          {{ product.description }}
        </div>

        <div class="mt-4 is-flex is-justify-content-space-between actions-bar">
          <div class="pl-3 pr-2 pt-2">
            <ProductCardStyleChooser :styles="product.styles" v-model:selectedStyle="selectedStyle"/>
          </div>
          <div class=""> <!-- TODO: this is causing problems with the carousel -->
            <ProductCardCartManager :styles="product.styles" v-model:selectedStyle="selectedStyle"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "scss/variables";
@import "bulma/sass/utilities/mixins";

.card {
  height: 100%;
  cursor: pointer;
  transition: 0.3s all;
  border-radius: 15px;
  text-align: left;

  .actions-bar {
    height: 40px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      height: 104px;
    }
  }

  .card-content {
    .media {
      margin-bottom: 0;
    }
  }

  p.title {
    line-height: 1.4;

    a {
      transition: 0.2s color ease-in-out;
    }
  }

  &:hover {
    @include tablet {
      transform: scale(1.04);
    }

    p.title {
      a {
        color: $pink;
      }
    }
  }

  .description {
    position: relative;
    height: 96px;
    overflow: hidden;

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70%;
      height: 1.2em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
  }
}

</style>


