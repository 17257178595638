<script lang="ts" setup>
import {ref, watch} from 'vue';
import svgRedo from "~/assets/icons/redo.svg?raw";

const props = defineProps<{ styles: ProductStyle[], selectedStyle: ProductStyle }>();
const emits = defineEmits(['update:selectedStyle']);
const localSelectedStyle = ref(props.selectedStyle);

const updateSelectedStyle = (style: ProductStyle) => {
    localSelectedStyle.value = style;
    emits('update:selectedStyle', style);
};

watch(
    () => props.selectedStyle,
    (newStyle) => {
        if (newStyle === localSelectedStyle.value) {
            return;
        }
        localSelectedStyle.value = newStyle;
    },
    {immediate: true}
);

</script>

<template>
    <div class="columns is-mobile mobile-centered">
        <div class="column"
             v-for="style in props.styles"
             :key="style.id"
        >
            <div
                :style="{ backgroundColor: style.colour_hex }"
                :class="{ active: localSelectedStyle.id === style.id }"
                class="style-box product-card"
                @click.stop="updateSelectedStyle(style)"
                :title="`Style: ${style.name_long}`"
            >
              <div class="alternative" v-if="style.is_alternative" v-html="svgRedo"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.column {
  .style-box.product-card {
    .alternative {
      width: 100%;
      display: flex;
      justify-content: center;

      svg {
        width: 20px;
        fill: rgba(0, 0, 0, 0.1);
        transition: fill 0.3s;
      }
    }

    &:hover {
      .alternative {
        svg {
          fill: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
    .columns.mobile-centered {
        justify-content: center;
    }
}

.column {
  padding: 5px 5px 5px 0;

  .style-box {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
    cursor: pointer;
    border: 3px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    transition: scale 0.7s;
    display: flex;
    justify-content: right;
    background-color: #f0f0f0;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2), inset -2px -2px 5px rgba(255, 255, 255, 0.5);

    &:hover {
      transition: scale 0.2s;
      scale: 1.3;
    }

    &.active {
      border: 3px dashed rgba(45, 45, 45, 0.6);
    }

    &:active {
      scale: 1.15;
    }
  }
}
</style>
