<script lang="ts" setup>

const props = defineProps<{
    regularPrice: string | number;
    salePrice: string | number | null;
}>();

const onSale = props.salePrice !== null;
const percentageSaved = onSale
    ? Math.floor(((+props.regularPrice - +props.salePrice) / +props.regularPrice) * 100)
    : 0;
</script>

<template>
    <div class="price-display is-justify-content-center">
        <p class="price" v-if="onSale">
            <span class="has-text-danger is-size-6">
                {{ '$' + salePrice }}
            </span>
            <span class="has-text-grey-light has-text-weight-semibold has-strike is-size-6">
                {{ '$' + regularPrice }}
            </span>
            <span v-if="percentageSaved >= 5" class="ml-1 tag is-success is-light is-size-7">
                Save {{ percentageSaved }}%
            </span>
        </p>
        <p class="price" v-else>
            <span class="is-size-6">{{ '$' + regularPrice }}</span>
        </p>
    </div>
</template>

<style lang="scss" scoped>
.price-display {
  .price {
    display: flex;
    align-items: center;
    margin-bottom: 0;

      span:first-child {
          margin-right: 0.8rem;
      }
  }

  .has-strike {
    text-decoration: line-through;
  }
}
</style>
